<template>
  <div>
    <!-- <validation-observer ref="asd"> -->

    <b-form-group
      label-cols-md="4"
      :label="label"
      :description="desc"
    >
      <validation-provider
        #default="{ errors }"
        :name="label"
        :rules="rules"
      >
        <b-input-group>
          <flat-pickr
            :value="value"
            class="form-control"
          />
          <b-input-group-append is-text>
            <feather-icon icon="CalendarIcon" />
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- </validation-observer> -->
  </div>
</template>

<script>
import {BFormGroup,BInputGroup,BInputGroupAppend} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'

  export default {
    components: {
      BFormGroup,
      ValidationProvider,
      flatPickr,
      BInputGroup,
      BInputGroupAppend
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      desc: {
        type: String,
        default: ''
      },
      rules: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        selected: this.value,
      }
    },
    methods:{
      onSelected() {
      this.$emit('input', this.selected)
    },
  }
    
    
  }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>