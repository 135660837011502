<template>
  <b-row>
    <b-col cols="6">
      <b-card title="Informasi">
        <validation-observer ref="simpleRules">
          <b-form>
            <form-input
              v-model="number"
              rules="required"
              label="Nomor Klaim"
              placeholder="Nomor Klaim"
            />
            <form-v-select
              ref="spb"
              v-model="spb"
              label="Nomor SPB"
              rules="required"
              :item_text="'number'"
              placeholder="Select SPB"
              @input="changeSPB"
            />
            <form-date
              v-model="request_date"
              rules="required"
              label="Tgl Pengajuan"
              placeholder="Tgl Pengajuan"
            />
            <form-file
              ref="file"
              v-model="file"
              label="Upload File"
              @input="changeFile"
            />
            <!-- <form-date
              v-model='used_date'
              rules="required"
              label='Tgl Realisasi'
              placeholder='Tgl Realisasi'
            /> -->
            <!-- <form-input
              v-model='note'
              label='Keterangan'
              placeholder='Keterangan'
            /> -->
            <hr>
            <b-form-group
              label-cols-md="4"
              label=""
            >
              <b-button
                class="mr-1"
                size="sm"
                variant="primary"
                type="button"
                @click.prevent="validationForm"
              >
                <span v-if="loading">
                  <b-spinner small />
                  Loading...
                </span>
                <span v-else>Simpan</span>
              </b-button>
              <b-button
                class="mr-1"
                size="sm"
                variant="warning"
                type="button"
                @click.prevent="$router.go(-1)"
              >
                <span>Batal</span>
              </b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
    <b-col cols="6">
      <b-card title="Kendaraan">
        <form-v-select
          ref="vehicle"
          v-model="vehicle"
          label="Nopol Kendaraan"
          placeholder="Select Kendaraan"
          :item_text="'number_plate'"
          @input="changeVehicle"
        />
        <form-v-select
          ref="maintenance"
          v-model="maintenance"
          label="Perawatan"
          placeholder="Select Perawatan"
        />
        <hr>
        <b-form-group
          label-cols-md="4"
          label=""
        >
          <b-button
            style="float:right;"
            size="sm"
            variant="success"
            type="button"
            @click="addDetail()"
          >
            <span>Tambah</span>
          </b-button>
        </b-form-group>
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-card>
        <b-tabs>
          <b-tab :active="tabActiveMaintenance">
            <template #title>
              <feather-icon icon="TruckIcon" />
              Detail Perawatan
            </template>
            <b-card>
              <b-table
                striped
                responsive
                :items="details"
                :fields="tableDetails"
              >
                <template #cell(_)="data">
                  <span
                    class="mr-1"
                  >
                    <b-link @click="confirmDelete(data)">
                      <b-badge variant="danger">
                        <feather-icon icon="TrashIcon" />
                      </b-badge>
                    </b-link>
                  </span>
                </template>
                <template
                  v-for="(_, slot) of $scopedSlots"
                  v-slot:[slot]="scope"
                >
                  <slot
                    :name="slot"
                    v-bind="scope"
                  />
                </template>
              </b-table>
            </b-card>
          </b-tab>
          <b-tab :active="tabActiveFile">
            <template #title>
              <feather-icon icon="FileIcon" />
              Daftar File Upload
            </template>
            <b-card>
              <b-table
                striped
                responsive
                :items="detailFile"
                :fields="tableDetailFile"
              >
                <template #cell(_)="data">
                  <span
                    class="mr-1"
                  >
                    <b-link @click="confirmDeleteFile(data)">
                      <b-badge variant="danger">
                        <feather-icon icon="TrashIcon" />
                      </b-badge>
                    </b-link>
                  </span>
                </template>
                <template
                  v-for="(_, slot) of $scopedSlots"
                  v-slot:[slot]="scope"
                >
                  <slot
                    :name="slot"
                    v-bind="scope"
                  />
                </template>
              </b-table>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BCard, BForm, BRow, BCol, BTable, BLink, BBadge, BFormGroup, BFormSelect, BFormInput, BButton, BSpinner, BTabs, BTab} from 'bootstrap-vue'
import baseSelect from '@/views/base/BaseSelect.vue'
import FormInput from '@/views/base/form/FormInput.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import FormFile from '@/views/base/form/FormFile.vue'
import FormDate from '@/views/base/form/FormDate.vue'
import moment from 'moment'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    baseform,
    BForm,
    BCard,
    BTable,
    BLink,
    BBadge, 
    BFormGroup,
    BFormSelect,
    BFormInput,
    baseSelect,
    FormInput,
    FormVSelect,
    BButton,
    BRow, 
    BCol,
    FormFile,
    FormDate,
    BSpinner,
    BTabs,
    BTab
  },
  data() {
    return {
      user:null,
      userType:null,
      dataurl:"/claim",
      baseroute:"v-vehicle-claim",
      tableDetails:[
        { key: 'name', label: 'Nama Perawatan'},
        { key: 'plan_cost', label: 'Nilai Klaim' },
        { key: 'status', label: 'Status'},
        '_'
      ],
      tableDetailFile:[
        { key: 'file', label: 'Nama File'},
        '_'
      ],
      detailFile:[],
      details:[],
      paramsMaintenance:[],
      paramsFile:[],
      spb:null,
      vehicle:null,
      maintenance:null,
      branch_destination:null,
      request_date:moment().format('Y-MM-D'),
      used_date:moment().format('Y-MM-D'),
      note:"",
      note_vehicle:"",
      file:null,
      path:null,
      loading:false,
      address:"",
      number:"",
      vehicle_id:null,
      tabActiveMaintenance:true,
      tabActiveFile:false
    }
  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
  },
  mounted(){
    if(this.user)
      this.fetchDataSPB()

    if(this.$route.params.id)
      this.fetchData()
  },
  methods: {
    fetchData(){
      this.$http.get(this.dataurl+'/'+this.$route.params.id).then(res=>{
        let data = res.data
        this.number = data.number
        this.request_date = data.date
        this.path = data.file
        this.details = data.maintenances
        this.detailFile = data.files
        for(let i=0; i<this.details.length; i++){
          this.paramsMaintenance.push(this.details[i]['id'])
        }
      })
    },
    fetchDataSPB(){
      var url = this.userType=='vendor'?'vendor/'+this.user.id+'/spb':'spb'
      this.$http.get(url+'?status=distributed,bapp_requested,bapp_approved,bapp_published&length=').then(res=>{
        this.optionSPB = res.data.data
        this.$refs.spb.refreshData(this.optionSPB)
      })
    },
    fetchDataVahicle(spb_id){
      this.$http.get('spb/'+spb_id+'/vehicle?length=').then(res=>{
        this.optionVehicle = res.data.data
        this.$refs.vehicle.refreshData(this.optionVehicle)
      })
    },
    fetchDataMaintenance(vehicle_id){
      this.$http.get('vehicle/'+vehicle_id+'/maintenance?length=').then(res=>{
        this.optionVehicle = res.data.data
        this.$refs.maintenance.refreshData(this.optionVehicle)
      })
    },
    changeSPB(data){
      if(data == null){
        this.vehicle = null
        this.maintenance = null
        this.$refs.maintenance.refreshData([])
      }else{
        this.vehicle = null
        this.maintenance = null
        this.$refs.maintenance.refreshData([])
        this.fetchDataVahicle(data.id)
      }
      this.resetDetail()
    },
    changeVehicle(data){
      if(data == null){
        this.vehicle_id = null
        this.maintenance = null
        this.$refs.maintenance.refreshData([])
      }else{
        if(data.vehicle_id){
          this.maintenance = null
          this.$refs.maintenance.refreshData([])
          this.vehicle_id = data.vehicle_id
          this.fetchDataMaintenance(data.vehicle_id)
        }
      }
      this.resetDetail()
    },
    confirmDelete(data){
      this.details.splice(data.index, 1)
      this.paramsMaintenance.splice(data.index, 1)
    },
    confirmDeleteFile(data){
      this.detailFile.splice(data.index, 1)
      this.paramsFile.splice(data.index, 1)
    },
    resetDetail(){
      this.details = []
      this.paramsMaintenance = []

    },
    addDetail() {
      if(this.maintenance == null ) 
        return this.$bvToast.toast('Please correct perawatan', {
            title: 'Error',
            solid: true,
            variant:'danger'
          })

      this.details.push({
        id:this.maintenance.id,
        name: this.maintenance.name,
        plan_cost: this.maintenance.plan_cost,
        status: this.maintenance.status,
      })
      this.setActiveTab('maintenance')
      this.paramsMaintenance.push(this.maintenance.id)
      this.maintenance = null
    },
    validationForm(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if(this.paramsMaintenance.length == 0)
            return this.$bvToast.toast('perawatan tidak boleh kosong', {
              title: 'Error',
              solid: true,
              variant:'danger'
            })

          if(this.paramsFile.length == 0)
            return this.$bvToast.toast('file upload tidak boleh kosong', {
              title: 'Error',
              solid: true,
              variant:'danger'
            })

          let params = {
            number: this.number,
            date: this.request_date,
            spb_id: this.spb.id,
            vehicle_id: this.vehicle_id,
            maintenances: this.paramsMaintenance,
            files:this.paramsFile
          }
          
          this.loading = true
          let http = null
          if(this.$route.params.id)
            http = this.$http.put(this.dataurl+'/'+this.$route.params.id, params)
          else
            http = this.$http.post(this.dataurl, params)
            
          http.then(()=>{
            this.successSubmit()
          })
          .catch(err=>{
            this.errorSubmit(err)
          })
          .finally(() => {this.loading = false})
        }else{
          this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    successSubmit(){
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      this.$router.go(-1)
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    changeFile(data){
      if(data){
        let formData = new FormData()
        formData.append('file', data)
        this.$http.post('upload/image', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res=>{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success Upload File',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.setActiveTab('file')
          this.detailFile.push({file:res.data.path})
          this.paramsFile.push(res.data.path)
          this.$refs.file.reset()
        })
      }
    },
    setActiveTab(tab){
      if(tab == 'file'){
        this.tabActiveMaintenance = false
        this.tabActiveFile = true
      }else{
        this.tabActiveMaintenance = true
        this.tabActiveFile = false
      }
    }
    
  }
}
</script>